import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "p-5" }, [_c("div", { staticClass: "menu px-3 pt-3" }, [_c("p", { staticClass: "font-weight-bold" }, [_vm._v("Reports")]), _c("div", { staticClass: "row pb-0" }, [_c("div", { staticClass: "col-lg-4 pb-3" }, [_c("a-card", { staticStyle: { "width": "100%" }, attrs: { "title": "Sterilisation" }, on: { "click": function($event) {
    return _vm.pushRouter("reports/sterilisation");
  } } })], 1), _c("div", { staticClass: "col-lg-4 pb-3" }, [_c("a-card", { staticStyle: { "width": "100%" }, attrs: { "title": "Live Inventory Index" }, on: { "click": function() {
    return _vm.preview("LiveInventoryIndex");
  } } })], 1), _c("div", { staticClass: "col-lg-4 pb-3" }, [_c("a-card", { staticStyle: { "width": "100%" }, attrs: { "title": "Reports Printed Log" }, on: { "click": function($event) {
    return _vm.pushRouter("reports/reports-printed-log");
  } } })], 1), _c("div", { staticClass: "col-lg-4 pb-3" }, [_c("a-card", { staticStyle: { "width": "100%" }, attrs: { "title": "Trial Inventory Index" }, on: { "click": function() {
    return _vm.preview("TrialInventoryIndex");
  } } })], 1), _c("div", { staticClass: "col-lg-4 pb-3" }, [_c("a-card", { staticStyle: { "width": "100%" }, attrs: { "title": "Sterilisation Processes" }, on: { "click": function($event) {
    return _vm.pushRouter("reports/sterilisation-processes");
  } } })], 1), _c("div", { staticClass: "col-lg-4 pb-3" }, [_c("a-card", { staticStyle: { "width": "100%" }, attrs: { "title": "Reports WWRetorts" }, on: { "click": function() {
    return _vm.preview("WWRetorts");
  } } })], 1), _c("div", { staticClass: "col-lg-4 pb-3" }, [_c("a-card", { staticStyle: { "width": "100%" }, attrs: { "title": "Reports WWSeasonalRetorts" }, on: { "click": function() {
    return _vm.preview("WWSeasonalRetorts");
  } } })], 1)])])]);
};
var staticRenderFns$1 = [];
var ReportsMenu_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "ReportMenu",
  data() {
    return {
      liveInventoryIndexLoading: false
    };
  },
  computed: {
    isLiveInventoryIndexLoading() {
      return this.liveInventoryIndexLoading;
    }
  },
  methods: {
    preview(report) {
      const data = {
        report,
        reportType: "steri",
        reportParams: {}
      };
      this.axios.post(`${apiUrl}/identities/reports`, data).then((res) => {
        if (!res || !res.data || !res.data.reportViewerUri) {
          this.$notification["error"]({
            message: "Report not found!"
          });
        } else {
          window.open(res.data.reportViewerUri, "_blank");
        }
      }, (err) => {
        this.$notification["error"]({
          message: err.message || "Errors!"
        });
      });
    },
    pushRouter(url) {
      this.$router.push("/sterilization-specifications/" + url);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "8d106634", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ReportsMenu = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("reports-menu");
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    ReportsMenu
  },
  data() {
    return {};
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
